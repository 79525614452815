import React from 'react';
import StyledButton from '../components/StyledButton';
import ProfilePic from "../images/IMG_8729.png";

import './WelcomePage.css';  // Import the CSS

const WelcomePage = () => {
  return (
    <div className="container">

      <div className="profile-column">
        <img src={ProfilePic} alt="Jared" className="profile-pic" />
      </div>

      <div className="bio-column">
        <div className="bio-text">
          <p>Hello there, my name's Jared.</p>
          <p>
            I studied Computer Science at the <a href="https://illinois.edu/" rel="noopener noreferrer" target="_blank">University of Illinois at
              Urbana-Champaign</a> and graduated in Spring 2018.
          </p>
          <p>I'm a Software Engineer.</p>
          <p>I spend my free time working on personal projects, running, and playing with my dog.</p>
        </div>

        <div className="buttons">
          <StyledButton
            text={"LinkedIn"}
            onClick={() => window.open('https://www.linkedin.com/in/jaredfranzone/', '_blank', 'noopener,noreferrer')}
            backgroundColor={'rgb(45, 100, 188)'} />
        </div>
      </div>
    </div>
  );
}

export default WelcomePage;
