import React from 'react';

const StyledButton = ({ onClick, text = 'Subscribe', backgroundColor }) => (
  <button
    className="button-fixed-width"
    onClick={onClick}
    style={{
      margin: '10px',
      backgroundColor,
      color: 'white',
      font: '23px Arial Black',
      borderRadius: '10px',
      padding: '20px',
      minWidth: '350px',
      cursor: 'pointer',
      border: 'none',
    }}
  >
    {text}
  </button>
);

export default StyledButton;
