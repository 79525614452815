
import React from 'react';

const NewsletterSuccessfulSubPage = () => {

  return (

    <div className="main">

      <p><br />
      </p>

      <h2>
        You're sucessfully subscribed to <br /><b>The Jared Franzone Newsletter</b>!
      </h2>

      <h3>
        Prepare to have your mind blown...
      </h3>

      <h4>
        (The newsletter is sent once a month, on the last <b>Friday</b> of every month)
      </h4>


    </div>



  );

}

export default NewsletterSuccessfulSubPage;