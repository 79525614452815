import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WelcomePage from './pages/WelcomePage';
import NewsletterPage from './pages/NewsletterPage';
import NewsletterSuccessfulSubPage from './pages/NewsletterSuccessfulSubPage';
import { Link } from 'react-router-dom';

const App = () => {
  return (
    <div>


      <Router>
        <header style={{ padding: '9px', backgroundColor: 'rgb(51, 51, 51)', color: 'white', textAlign: 'left' }}>
          <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/"><h1>Jared Franzone</h1></Link>
        </header>
        <main style={{ padding: '20px', textAlign: 'center' }}>

          <Routes>
            <Route path="/" element={<WelcomePage />} />
            <Route path="/newsletter" element={<NewsletterPage />} />
            <Route path="/newsletter/successfulsubscription" element={<NewsletterSuccessfulSubPage />} />
          </Routes>
        </main>

      </Router>

    </div>

  );
}

export default App;
