import React, { useState } from 'react';
import { Puff } from 'react-loader-spinner';

const MAX_NAME_LENGTH = 70;

const NewsletterPage = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [successfulSub, setIsSuccessfulSub] = useState(null);
    const handleSubmit = (e) => {
        e.preventDefault();

        setIsLoading(true);

        // validate input
        if (email.length > MAX_NAME_LENGTH || firstName > MAX_NAME_LENGTH || lastName > MAX_NAME_LENGTH ||
            email === 0 || !email.includes("@")) {
            setIsLoading(false);

            console.log("Invalid Email");
            return;
        }

        // format email
        const unformattedemail = email;
        setEmail(unformattedemail.toLowerCase().trim())

        console.log("sendRequest:");

        const objToSending = {
            "email": email,
            "firstname": firstName,
            "lastname": lastName
        };

        // let currentComponent = this;

        fetch("/prod/subscribe", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(objToSending)
        })
            .then(data => {

                setFirstName('');
                setLastName('');
                setEmail('');
                setIsLoading(false);
                if (data.error) {
                    // alert("Your subscription request failed, please try again later.");
                    return;
                }
                setIsSuccessfulSub(true);
            })
            .catch(error => {
                setIsLoading(false);
                setIsSuccessfulSub(false);
                console.error('An error occurred:', error);
            });
    };


    return (
        <div style={{ padding: '40px', backgroundColor: '#f0f0f0', borderRadius: '10px', maxWidth: '600px', margin: 'auto', marginTop: '50px' }}>
            <h1 style={{ textAlign: 'center', marginBottom: '20px', color: '#333' }}>Join My Newsletter</h1>
            <p style={{ textAlign: 'center', color: '#666' }}>
                Discover cool new things every month. Enter your details and stay updated!
            </p>
            <form onSubmit={handleSubmit} style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '20px', boxShadow: '0 0 10px rgba(0,0,0,0.1)', margin: '0 10px' }}>
                <div style={{ marginBottom: '20px' }}>
                    <label htmlFor="firstName" style={{ display: 'block', marginBottom: '5px', color: '#333' }}>Name: *</label>
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        placeholder="Name"
                        style={{ width: 'calc(100% - 20px)', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', margin: '0 10px' }}
                        onFocus={(e) => e.target.placeholder = ""}
                        onBlur={(e) => e.target.placeholder = "Name"}
                    />
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <label htmlFor="email" style={{ display: 'block', marginBottom: '5px', color: '#333' }}>Email: *</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="Email"
                        style={{ width: 'calc(100% - 20px)', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', margin: '0 10px' }}
                        onFocus={(e) => e.target.placeholder = ""}
                        onBlur={(e) => e.target.placeholder = "Email"}
                    />
                </div>
                <button type="submit" style={{ display: 'block', width: 'calc(100% - 20px)', padding: '10px', borderRadius: '5px', backgroundColor: '#3498db', color: '#fff', fontSize: '16px', border: 'none', cursor: 'pointer', margin: '0 10px' }}>Subscribe</button>
            </form>
            {isLoading && <div style={{ textAlign: 'center', marginTop: '20px' }}><Puff height="80" width="80" color="#3498db" /></div>}
            {successfulSub !== null && (
                <div style={{ textAlign: 'center', marginTop: '20px', color: successfulSub ? '#28a745' : '#dc3545' }}>
                    {successfulSub ? "You're successfully subscribed! Check your email to verify and confirm your subscription." : "Sorry, an error occurred when subscribing, please try again."}
                </div>
            )}
        </div>
    );
}

export default NewsletterPage;
